import { useEffect, useMemo } from 'react';
import { usePostMessageContext } from '../PostMessageContext';
import { MessageListener, MessagePayload, MessageType } from '../types';

export const useShellMessageListener = <
  T extends MessagePayload = MessagePayload,
>(
  id: string,
  type: MessageType,
  listener: MessageListener<T>,
) => {
  const memoizedListener = useMemo<MessageListener>(
    () => listener as MessageListener,
    [listener],
  );
  const { addListener, removeListener } = usePostMessageContext();

  useEffect(() => {
    addListener(id, type, memoizedListener);

    return () => {
      removeListener(id, type);
    };
  }, [id, type, memoizedListener, addListener, removeListener]);
};
