import { AppsConfiguration } from '@/lib/config/apps';
import Config from '@/lib/config';
import { useMemo } from 'react';

type TargetApp = keyof AppsConfiguration;

type Params = Record<string, string | null | undefined>;

type BuildAppUrlType = (
  target: TargetApp,
  route: string,
  params?: Params | null,
  queryParams?: Record<string, string> | null,
) => string;

export const buildAppUrl: BuildAppUrlType = (
  target,
  route,
  params,
  queryParams,
) => {
  const { baseUrl } = Config.apps[target];
  let path = route;

  if (params) {
    path = path.replace(/\{(\w+)}/g, (match, key) => {
      return params?.[key] || match;
    });
  }

  let result = baseUrl;

  if (path !== '/') {
    result = `${baseUrl}/${path}`;
  }

  try {
    const url = new URL(result);
    if (queryParams) {
      Object.entries(queryParams).forEach(([name, value]) => {
        url.searchParams.set(name, value);
      });
    }

    return url.toString();
  } catch (e) {
    return result;
  }
};

export const useBuildAppUrl: BuildAppUrlType = (
  target,
  route,
  params,
  queryParams,
) =>
  useMemo(
    () => buildAppUrl(target, route, params, queryParams),
    [target, route, params, queryParams],
  );
