import { StyledLogo, StyledTopBar, StyledTopBarItem } from '../Styled';
import { ProfileButton } from './ProfileButton';
import { Notifications, Search, Tasks } from '@/lib/components/icons';
import { Box, BoxProps } from 'grommet';
import { FC, ReactNode } from 'react';
import Link from 'next/link';
import { AIButton } from '@/lib/components/app/TopBar/AIButton';
import { useAuth0 } from '@auth0/auth0-react';

type TopBarItemProps = {
  icon: ReactNode;
};

const TopBarItem: FC<TopBarItemProps> = ({ icon }) => {
  return <StyledTopBarItem>{icon}</StyledTopBarItem>;
};

export const TopBar: FC<BoxProps> = (props) => {
  const { user } = useAuth0();

  return (
    <StyledTopBar {...props} direction="row" justify="between" align="center">
      <Link href="/" style={{ display: 'flex' }}>
        <StyledLogo
          src="/roadz.png"
          height="43px"
          width="138px"
          alt="Roadz Logo"
          a11yTitle="Roadz Logo"
        />
      </Link>
      <Box direction="row" height={{ height: '100%' }}>
        {user && <AIButton />}
        <TopBarItem icon={<Search />} />
        <TopBarItem icon={<Tasks />} />
        <TopBarItem icon={<Notifications />} />
        <ProfileButton />
      </Box>

      {/* NOTE: Temporarily disabled */}
      {/* <StyledAppsTab justify="center" align="center"> */}
      {/*   <StyledAppsTabLabel>Apps</StyledAppsTabLabel> */}
      {/* </StyledAppsTab> */}
    </StyledTopBar>
  );
};
