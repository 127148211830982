import { FC, useCallback, useState } from 'react';
import {
  Box,
  Button,
  DropButton,
  Form,
  FormExtendedEvent,
  Heading,
  Text,
  TextArea,
} from 'grommet';
import { FormClose } from 'grommet-icons';
import styled from 'styled-components';
import config from '@/lib/config';

const StyledButton = styled(DropButton)`
  background-color: white;
  color: #376e8d;
  border-color: #376e8d;
`;

export const AIButton: FC = () => {
  const [prompt, setPrompt] = useState('');
  const [open, setOpen] = useState(false);

  const onSubmit = useCallback(
    (e: FormExtendedEvent) => {
      e.preventDefault();

      if (!prompt) {
        return;
      }

      const url = new URL(config.apps.ai.baseUrl);
      url.searchParams.set('prompt', prompt);

      //window.open(url);

      // for demo purposes only
      const iframe = document.querySelector('iframe');
      if (iframe) {
        iframe.src = url.href;
      }

      setOpen(false);
    },
    [prompt],
  );

  return (
    <Box justify="center">
      <StyledButton
        label="Ask AI"
        dropAlign={{
          top: 'bottom',
        }}
        onClick={() => setOpen(true)}
        open={open}
        dropProps={{
          style: {
            boxShadow: 'rgba(0, 0, 0, 0.5) 0px 2px 10px',
          },
        }}
        dropContent={
          <Form onSubmit={onSubmit}>
            <Box pad="small" justify="end">
              <Box
                direction="row"
                justify="between"
                margin={{ vertical: 'xsmall' }}
              >
                <Heading level="4" margin="none">
                  ASK AI
                </Heading>

                <Button onClick={() => setOpen(false)}>
                  <FormClose />
                </Button>
              </Box>

              <Text>Ask a question about your fleet, data or metrics</Text>

              <Box margin={{ vertical: 'small' }}>
                <TextArea
                  autoFocus
                  rows={7}
                  value={prompt}
                  onChange={(e) => {
                    setPrompt(e.target.value);
                  }}
                />
              </Box>

              <Box align="end" margin={{ bottom: 'xsmall' }}>
                <Button primary label="Ask question" type="submit" />
              </Box>
            </Box>
          </Form>
        }
        style={{
          background: 'linear-gradient(90deg, #183E44 0%, #168291 100%)',
          border: '0 none',
          borderRadius: '50px',
          color: 'white',
        }}
      />
    </Box>
  );
};
